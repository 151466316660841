import API from '@aws-amplify/api';
import { getCorporationDetail,authenServicePublicApi } from '@/graphql/queries';
import { createNewCorporation, editCorporation, createGuestContract,blockAndDeleteOrganization,editCorporationDetail, updateRootOrganizationPermission } from '@/graphql/mutations';
import I18n from '@/rI18n';
import Constant from '@/common/constant/Constant';
import Utils from '@/common/utils/utils';
import _ from 'lodash';
import Logger from '@/common/utils/Logger';
import * as queries from '@/graphql/queries';
import * as DynamoDBCorporationRequestDto from './dynamoDBRequestDto/DynamoDBCorporationRequestDto';
import { getPublicAPI } from './publicService';
import localStorageUtil from '@/common/utils/localStorageUtil';
import crypto from 'crypto-js';

const { AUTH_TYPE } = require('aws-appsync');


/**
 * Get corporation detail by corporationDomain
 * @param {*} APIClient 
 * @param {*} corporationDomain 
 * @returns corporationDomain
 */
export const getCorporationDetailByCorporationDomain = async (APIClient, corporationDomain) => {
    let corporationDetail = {
        corporationName: '',
        corporationLogo: Constant.LOGO_DEFAULT,
        isLoadDataWithAPI: true,
        corporationSetting: {}
    };
    if (Utils.isEmptyString(corporationDomain)) {
        return corporationDetail;
    }
    try {
        // build params
        let queryParams = {
            query: queries.getCorporationList,
            variables: {
                params: {
                    expression: DynamoDBCorporationRequestDto
                        .getExpressionCorporationByCorporationDomain(corporationDomain)
                }
            }
        };
        // call api get corporation detail
        let resData = await API.graphql(queryParams);
        if (!Utils.isEmptyArray(resData.data?.getCorporationList)) {
            const corporationList = resData.data?.getCorporationList;

            let infoCorporation = localStorageUtil.getContractTypeAndSubDomainCorp();
            infoCorporation = Utils.convertJSONDataToObject(infoCorporation);
            const contractType = infoCorporation?.contractType;
            if (
                [
                    Constant.CORPORATION_CONTRACT_TYPE.GUEST,
                    Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK
                ].includes(contractType) && infoCorporation.subDomainInvite
            ) {
                corporationDetail = corporationList.find(corp => corp.inviterOrganizationDomain === infoCorporation.subDomainInvite);
            } else {
                corporationDetail = resData.data.getCorporationList[0];
            }

            // get logo url
            if (!Utils.isEmptyString(corporationDetail.image)) { 
                let corporationLogo = Utils.getCloudFrontUrlForCorporationLogo({
                    type: Constant.S3_FILE_TYPE.CORPORATION_LOGO_PROFILE_FILE,
                    image: corporationDetail.image,
                    corporationId: corporationDetail.organizationDomain,
                    corporationDetail: corporationDetail
                });
                corporationDetail.corporationLogo = corporationLogo;
            } else {
                corporationDetail.corporationLogo = Constant.LOGO_DEFAULT;
            }

            // set corporation name
            corporationDetail.corporationName = Utils.getCorporationName(Constant.LANGUAGE_TYPE.JP, corporationDetail);

            // set logo file name
            corporationDetail.logoFileName = Utils.isEmptyString(corporationDetail.logoFileName)
                ? Constant.LOGO_FILE_NAME_DEFAULT
                : corporationDetail.logoFileName;
        }
    } catch (err) {
        console.log('getCorporationDetailByCorporationDomain error: ', err);
    }
    return corporationDetail;
};



const getCorporationInfoFormRequest = (requestBody) => {
    return {
        active: Constant.CORPORATION_ACTIVE.WAITING_APPROVE,
        mailAddress: requestBody.mailAddress.trim(),
        userId: requestBody.mailAddress.trim(),
        firstName: requestBody.firstName ? requestBody.firstName.trim() : '',
        lastName: requestBody.lastName ? requestBody.lastName.trim() : '',
        corporationPhone: requestBody.corporationPhone ? requestBody.corporationPhone.trim() : '',
        corporationName: requestBody.corpName ? requestBody.corpName.trim() : '',
        departmentName: requestBody.departmentName ? requestBody.departmentName.trim() : '',
        subDomain: requestBody.subDomain.trim(),
        password: crypto.AES.encrypt(requestBody.password.trim(), Constant.PASSWORD_AES_ENCODE_KEY).toString(),
        contractType: requestBody.contractType
            ? requestBody.contractType
            : Constant.CORPORATION_CONTRACT_TYPE.STANDARD,
        maxGeneralUser: requestBody.generalUser,
        jobTitle: requestBody.jobTitle ? requestBody.jobTitle.trim() : '',
        industry: requestBody.industry ? requestBody.industry.trim() : '',
        companyName: requestBody.companyName ? requestBody.companyName.trim() : '',
    };
};

async function createOrganization(APIClient, requestBody) {
    let organizationInfor = getCorporationInfoFormRequest(requestBody);
    organizationInfor = {
        ...organizationInfor,
        rootFlag: Constant.ROOT_FLAG.FALSE,
        deskService: Constant.STATUS_SERVICE_PERMISSION.ON,
        knowledgeService: Constant.STATUS_SERVICE_PERMISSION.OFF,
        taskManagementService:  Constant.STATUS_SERVICE_PERMISSION.OFF,
        publicService:  Constant.STATUS_SERVICE_PERMISSION.OFF,
    };
    let message = '';
    try {
        const createCorporationResponse = await API.graphql({
            query: createNewCorporation,
            variables: {
                ...organizationInfor
            },
            authMode: AUTH_TYPE.API_KEY,
        });

        if (
            createCorporationResponse == null ||
            createCorporationResponse.data.createNewCorporation == null
        ) {
            return 'Create Organization fail';
        }
        return message;
    } catch (ex) {
        console.log('createOrganization exception', ex);
        const cleaningMessage = ex.errors[0].message?.replace("ApiError==", "")
        message = I18n.get(ex.errors ? cleaningMessage : 'Error');
    }
    return message;
}

async function activeOrganization(APIClient, requestBody) {
    let organizationInfor = getCorporationInfoFormRequest(requestBody);
    organizationInfor.id = requestBody.id;
    organizationInfor.active = Constant.CORPORATION_ACTIVE.ACTIVE;
    organizationInfor.productId = requestBody.productId;
    organizationInfor.editionId = requestBody.editionId;
    organizationInfor.maxGeneralId = requestBody.maxGeneralUser;

    
    let message = '';
    try {
        const createCorporationResponse = await API.graphql({
            query: createNewCorporation,
            variables: {
                ...organizationInfor
            },
            authMode: AUTH_TYPE.API_KEY,
        });

        if (
            createCorporationResponse == null ||
            createCorporationResponse.data.createNewCorporation == null
        ) {
            return 'Create Organization fail';
        }
        return message;
    } catch (ex) {
        console.log('createOrganization exception', ex);
        // message = I18n.get(ex.errors ? ex.errors[0].message : 'Error');
        message = ex.errors ? ex.errors[0].message : 'Error';
    }
    return message;
}

async function rejectOrganization(APIClient, requestBody) {
    let organizationInfor = getCorporationInfoFormRequest(requestBody);
    organizationInfor.id = requestBody.id;
    organizationInfor.active = Constant.CORPORATION_ACTIVE.REJECT;
    
    let message = '';
    try {
        const createCorporationResponse = await API.graphql({
            query: createNewCorporation,
            variables: {
                ...organizationInfor
            },
            authMode: AUTH_TYPE.API_KEY,
        });

        if (
            createCorporationResponse == null ||
            createCorporationResponse.data.createNewCorporation == null
        ) {
            return 'rejectOrganization fail';
        }
        return message;
    } catch (ex) {
        console.log('rejectOrganization exception', ex);
        // message = I18n.get(ex.errors ? ex.errors[0].message : 'Error');
        message = ex.errors ? ex.errors[0].message : 'Error';
    }
    return message;
}

const createNewGuestContract = async (APIClient, requestBody) => {
    let guestContractRequestDto = getCorporationInfoFormRequest(requestBody);
    guestContractRequestDto.contractType = guestContractRequestDto.projectType === Constant.INVITE_PROJECT_TYPE.GUESTDESK ? Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK : Constant.CORPORATION_CONTRACT_TYPE.GUEST;
    guestContractRequestDto.projectType = requestBody.projectType;
    guestContractRequestDto.projectName = requestBody.projectName;
    guestContractRequestDto.inviter = requestBody.premiumId;
    guestContractRequestDto.userType = Constant.USER_TYPE.GENERAL;
    guestContractRequestDto.ticketId = requestBody.ticketId;
    guestContractRequestDto.projectInvite = requestBody.projectInvite;
    guestContractRequestDto.isCreateProjectInExistsOrg = requestBody.isCreateProjectInExistsOrg;

    guestContractRequestDto.active = Constant.CORPORATION_ACTIVE.WAITING_APPROVE;
    let organizationInfor = {
        guestContractRequestDto: guestContractRequestDto,
    };
    let message = '';
    try {
        const createCorporationResponse = await API.graphql({
            query: createGuestContract,
            variables: organizationInfor,
            authMode: AUTH_TYPE.API_KEY,
        });
        if (
            createCorporationResponse == null ||
            createCorporationResponse.data.createGuestContract == null
        ) {
            message = 'Create guest-contract fail';
        }

        if (createCorporationResponse.data.createGuestContract?.isCreateApprovePage) {
            message = "isCreateApprovePage";
        }

        return message;
    } catch (ex) {
        console.log('createNewGuestContract exception', ex);
        throw ex;
    }
};

/**
 * Get corporation detail by corporationId
 * @param {*} APIClient
 * @param {*} corporationId
 * @returns corporationDetail
 */
export const getCorporationDetailByCorporationId = async (APIClient, corporationId) => {
    try {
        let corporationDetail = await getCorporationByCorporationId(API, corporationId);
        // check response
        if (Utils.isEmptyObject(corporationDetail)) {
            return {
                corporationName: '',
                corporationLogo: Constant.LOGO_DEFAULT
            };
        }
        // get logo url
        if (!Utils.isEmptyString(corporationDetail.image)) {
            let corporationLogo = Utils.getCloudFrontUrlForCorporationLogo({
                type: Constant.S3_FILE_TYPE.CORPORATION_LOGO_PROFILE_FILE,
                image: corporationDetail.image,
                corporationId: corporationDetail.organizationDomain,
                corporationDetail: corporationDetail
            });
            corporationDetail.corporationLogo = corporationLogo;
        } else {
            corporationDetail.corporationLogo = Constant.LOGO_DEFAULT;
        }

        // set corporation name
        corporationDetail.corporationName = Utils.getTextByLanguage(Constant.LANGUAGE_TYPE.JP, corporationDetail.name);

        // set logo file name
        corporationDetail.logoFileName = Utils.isEmptyString(corporationDetail.logoFileName)
            ? Constant.LOGO_FILE_NAME_DEFAULT
            : corporationDetail.logoFileName;

        return corporationDetail;
    } catch (err) {
        console.log('getCorporationDetailByCorporationId error: ', err);
        return {
            corporationName: '',
            corporationLogo: Constant.LOGO_DEFAULT
        };
    }
};

/**
 *
 * @param {*} APIClient
 * @param {*} requestBody
 * @returns
 */
async function editOrganization(APIClient, requestBody) {
    const editCorporationResult = await API.graphql({
        query: editCorporation,
        variables: requestBody,
    });
    return editCorporationResult.data.editCorporation;
}

/**
 * getListPremiumCorporation
 * @param {*} APIClient 
 * @param {*} filter 
 * @returns listCorporation
 */
export const getListPremiumCorporation = async (APIClient) => {
    // build params
    let queryParams = {
        query: queries.getCorporationList,
        variables: {
            params: {
                expression: DynamoDBCorporationRequestDto.prepareParamsGetAllPremiumCorporation()
            }
        }
    };

    // call api get corporation list
    let res = await API.graphql(queryParams);
    let lisCorporation = !Utils.isEmptyArray(res?.data?.getCorporationList) ? res?.data?.getCorporationList : [];

    return lisCorporation;
}

export const getListAllCorporation = async APIClient => {
    try {
        // build params
        let queryParams = {
            query: queries.getCorporationList,
            variables: {
                params: {
                    expression: DynamoDBCorporationRequestDto.prepareParamsGetAllCorporation()
                }
            }
        };

        // call api get corporation list
        let res = await API.graphql(queryParams);
        let lisCorporation = !Utils.isEmptyArray(res?.data?.getCorporationList)
            ? res?.data?.getCorporationList
            : [];

        return lisCorporation;
    } catch (error) {
        return [];
    }
};

/**
 *
 * @param {*} APIClient
 * @param {*} organizationDomain
 * @returns
 */
export const getCorporationByCorporationId = async (APIClient, organizationDomain, isGuestOrGuestDesk = false, inviterOrganizationDomain = '') => {
    if (!organizationDomain) {
        return {};
    }

    let corporationData = {};
    try {
        // build params
        let queryParams = {
            query: queries.getCorporationList,
            variables: {
                params: {
                    expression: DynamoDBCorporationRequestDto.getExpressionCorporationByCorporationDomain(
                        organizationDomain,
                        isGuestOrGuestDesk
                    )
                }
            },
        };
        // call api get corporation detail
        let resCorporationList = await API.graphql(queryParams);

        if (!Utils.isEmptyArray(resCorporationList.data.getCorporationList)) {
            let corporationList = resCorporationList.data.getCorporationList;

            if (isGuestOrGuestDesk && inviterOrganizationDomain) {
                corporationList = corporationList.filter(
                    corp => corp.inviterOrganizationDomain === inviterOrganizationDomain
                );
            }
            corporationData = corporationList[0];
        }
    } catch (err) {
        console.log('getProjectById error: ', err);
    }

    return corporationData;
};


export const getCorporationById = async (APIClient, id) => {
    try {
        let res = await API.graphql({
            query: getCorporationDetail,
            variables: {
                params: {
                    id: id
                }
            }
        });
        return !Utils.isEmptyObject(res.data.getCorporationDetail)
            ? res.data.getCorporationDetail
            : {};
    } catch (err) {
        console.log('getProjectById error: ', err);
        return {};
    }
};

/**
 *
 * @param {*} corporationId
 * @param {*} corrporationList
 * @returns
 */
const isGuestContract = (corporationId, corrporationList) => {
    if (Utils.isEmptyArray(corrporationList)) {
        return false;
    }
    let corporation = _.find(corrporationList, ['id', corporationId]);
    if (corporation) {
        return [Constant.CORPORATION_CONTRACT_TYPE.GUEST, Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK].includes(corporation?.contractType);
    }
    return false;
};


export const getListCorporationByIdList = async (APIClient, idList) => {
    const queryGetCorporationDetailList = [];
    idList.forEach(id => {
        queryGetCorporationDetailList.push(getCorporationById(API, id));
    });
    const listCorporation = await Promise.all(queryGetCorporationDetailList);
    return listCorporation;
};


export const approveRejectCreateGuestContract = async (APIClient, baseDto, type) => {
    let requestDto = {...baseDto};
    delete requestDto.linkInvite;
    requestDto.active = type ? type : Constant.CORPORATION_ACTIVE.REJECT;
    
    let organizationInfor = {
        guestContractRequestDto: requestDto,
    };
    let message = '';
    try {
        const createCorporationResponse = await API.graphql({
            query: createGuestContract,
            variables: organizationInfor,
            authMode: AUTH_TYPE.API_KEY,
        });
        if (
            createCorporationResponse == null ||
            createCorporationResponse.data.createGuestContract == null
        ) {
            message = 'Create guest-contract fail';
        }
        return message;
    } catch (ex) {
        console.log('createNewGuestContract exception', ex);
        if (!Utils.isEmptyArray(ex?.errors) && ex?.errors[0]?.message) {
            // return I18n.get(ex?.errors[0]?.message);
            return ex?.errors[0]?.message;
        }

        return 'SYSMSG001';
    }
}

const convertContractTypeToJPName = (contractType) => {
    let contractJPNAME = '' 
    switch (contractType) {
        case Constant.CORPORATION_CONTRACT_TYPE.STANDARD:
        case Constant.CORPORATION_CONTRACT_TYPE.TRIAL:
            contractJPNAME = 'トライアル'
            break;
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST:
        case Constant.CORPORATION_CONTRACT_TYPE.GUEST_DESK:
            contractJPNAME = 'ゲスト'
            break;
        default:
           break
    }
    return contractJPNAME
}

const blockAndDeleteOrg = async (APIClient, orgId, activeFunction, userId) => {
    const requestBody = {
        userId: userId,
        corporationId: orgId,
        updateActiveFunction: activeFunction
    };
    try{
        const resultUpdateActiveOrg = await API.graphql({
            query: blockAndDeleteOrganization,
            variables: {
                blockAndDeleteOrganizationRequestDto: requestBody
            }
        })
        return  resultUpdateActiveOrg.data.blockAndDeleteOrganization
    }catch(error){
        return {
            ...error,   
            success: false
        }
    }
}

const editCorporations = async (APIClient, requestDto) => {
    try {
        const result = await API.graphql({
            query: editCorporationDetail,
            variables: {
                editCorporationDetailRequestDto: requestDto,
            },
        });

        return result.data.editCorporationDetail;
    } catch (err) {
        Logger.error('editCorporationDetail', err);
        return null;
    }
}

const updatePermissionCorp = async(APIClient, requestDto) => {
    try {
        const result = await API.graphql({
            query: editCorporationDetail,
            variables: {
                editCorporationDetailRequestDto: requestDto,
            },
        });

        return result.data.editCorporationDetail;
    } catch (err) {
        Logger.error('editCorporationDetail', err);
        return null;
    }
}

export const getDataCorporationById = async (
    APIClient,
    organizationDomain,
    isGuestOrGuestDesk = false,
    inviterOrganizationDomain = ''
) => {
    let corporationDetail = await getCorporationByCorporationId(API, organizationDomain, isGuestOrGuestDesk, inviterOrganizationDomain)
    // get logo url
    if (!Utils.isEmptyString(corporationDetail.image)) {
        let corporationLogo = Utils.getCloudFrontUrlForCorporationLogo({
            type: Constant.S3_FILE_TYPE.CORPORATION_LOGO_PROFILE_FILE,
            image: corporationDetail.image,
            corporationId: corporationDetail.organizationDomain,
            corporationDetail: corporationDetail
        });
        corporationDetail.corporationLogo = corporationLogo;
    } else {
        corporationDetail.corporationLogo = Constant.LOGO_DEFAULT;
    }

    // set corporation name
    corporationDetail.corporationName = Utils.getCorporationName(Constant.LANGUAGE_TYPE.JP, corporationDetail);

    // set logo file name
    corporationDetail.logoFileName = Utils.isEmptyString(corporationDetail.logoFileName)
        ? Constant.LOGO_FILE_NAME_DEFAULT
        : corporationDetail.logoFileName;
    return corporationDetail;
}

const updateRootOrganizationPermissionById = async(APIClient, requestDto) => {
    try {
        
        const result = await API.graphql({
            query: updateRootOrganizationPermission,
            variables: {
                updateRootOrganizationPermissionRequestDto: requestDto,
            },
        });

        return result.data.updateRootOrganizationPermission;
    } catch (err) {
        Logger.error('updateRootOrganizationPermission', err);
        return null;
    }
}

const getListCorporationByEmail = async (APIClient, email) => {
    let res = await API.graphql({
        query: authenServicePublicApi,
        variables: {
            params: JSON.stringify({
                email: email
            })
        },
        authMode: Constant.AUTH_MODE_API_KEY,
    });

    return res.data.authenServicePublicApi
}

const getProductEndEditionDataById = async (APIClient, setIsLoading, setProductData, setEditionData, props, setErrorPage) => {
    setIsLoading(true)
    let params = {
        getApiType: Constant.GET_API_PUBLIC_TYPE.PREPARE_DATA_REGISTER_TRIAL,
    };

    let result = await getPublicAPI(API, params);
    if (result.success) {
        let data = Utils.convertJSONDataToObject(result.payload);
        setProductData({
            id: data.productId,
            name: data.productName
        });
        setEditionData({
            id: data.editionId,
            name: ' ('+data.editionName+')'
        });
    } else {
        setIsLoading(false);
        if (result.errors === 'MSG-ORG-TRIAL-EDITION-NOT-AVAILABLE') {
            setErrorPage({isError: true, msg: result.errors});
            return;
        }
    }
    setIsLoading(false);
}


/**
 * getCorporationAndContractDetail
 * @param {*} APIClient 
 * @param {*} params 
 * @returns 
 */
const getCorporationAndContractDetail = async (APIClient, params) => {
    if (!API || !params || Utils.isEmptyObject(params)) return;

    let res = await API.graphql({
        query: queries.getCorporationContractDetail,
        variables: {
            params: {
                ...params
            }
        },
    });

    return res.data.getCorporationContractDetail;
}

const organizationService = {
    createOrganization,
    activeOrganization,
    rejectOrganization,
    editOrganization,
    getListPremiumCorporation,
    createNewGuestContract,
    isGuestContract,
    getCorporationDetailByCorporationId,
    getCorporationById,
    getListCorporationByIdList,
    approveRejectCreateGuestContract,
    convertContractTypeToJPName,
    blockAndDeleteOrg,
    editCorporations,
    updatePermissionCorp,
    getDataCorporationById,
    updateRootOrganizationPermissionById,
    getListCorporationByEmail,
    getProductEndEditionDataById,
    getCorporationAndContractDetail,
};

export default organizationService;
