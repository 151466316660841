const itemStatusColor = [
    {id: 1, color: '#ffffff', background: '#7f7f7f'},
    {id: 2, color: '#ffffff', background: '#37c1c9'},
    {id: 3, color: '#ffffff', background: '#45b974'},
    {id: 4, color: '#ffffff', background: '#f6c569'},
    {id: 5, color: '#ffffff', background: '#ed7247'},
    {id: 6, color: '#ffffff', background: '#e34242'},
    {id: 7, color: '#000000', background: '#eaeaea'},
    {id: 8, color: '#000000', background: '#b0e7ea'},
    {id: 9, color: '#000000', background: '#abdfc0'},
    {id: 10, color: '#000000', background: '#fadda8'},
    {id: 11, color: '#000000', background: '#f7bca7'},
    {id: 12, color: '#000000', background: '#f3abab'},
];

export default itemStatusColor;
