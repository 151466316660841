import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingIndicator(props) {
    if (props.isLoading) {
        return (
            <div className={`loading-container`} data-cy="loading-page">
                <CircularProgress color="primary" style={{ display: 'block', textAlign: 'center', top: "45%", marginLeft: "-10px", left: "50%", position: "fixed", zIndex: "9999" }} />
                <div className="loading-blur"></div>
            </div>
        );
    }
    return null;
}