import Constant from '@/common/constant/Constant';
// import Utils from '@/common/utils/utils';


/**
 * Get coporation by corporationDomain
 * @param {*} organizationDomain 
 * @returns expressionStr
 */
export const getExpressionCorporationByCorporationDomain = (organizationDomain, isGuestOrGuestDesk = false) => {
    let filterExpression = isGuestOrGuestDesk ? '#active IN (:active_eq, :waiting_eq)' : '#active = :active_eq';
    let expressionAttributeValues = {};

    if(isGuestOrGuestDesk) {
        expressionAttributeValues = {
            ':organizationDomain': {
                S: organizationDomain
            },
            ':active_eq': {
                N: `${Constant.CORPORATION_ACTIVE.ACTIVE}`
            },
            ':waiting_eq': {
                N: `${Constant.CORPORATION_ACTIVE.WAITING_APPROVE}`
            }
        }
    }
    else {
        expressionAttributeValues = {
            ':organizationDomain': {
                S: organizationDomain
            },
            ':active_eq': {
                N: `${Constant.CORPORATION_ACTIVE.ACTIVE}`
            }
        }
    }

    let expression = {
        IndexName: Constant.GSI.ORGANIZATION_DOMAIN_INDEX,
        KeyConditionExpression: '#organizationDomain = :organizationDomain',
        FilterExpression: filterExpression,
        ExpressionAttributeNames: {
            '#organizationDomain': 'organizationDomain',
            '#active': 'active'
        },
        ExpressionAttributeValues: expressionAttributeValues
    };

    return JSON.stringify(expression);
};

/**
 * Get coporation by corporationDomain
 * @param {*} organizationDomain 
 * @returns expressionStr
 */
 export const getExpressionCorporationActiveAndBlockByCorporationDomain = organizationDomain => {
    let expression = {
        IndexName: Constant.GSI.ORGANIZATION_DOMAIN_INDEX,
        KeyConditionExpression: '#organizationDomain = :organizationDomain',
        FilterExpression: '#active = :active_eq OR #active = :block_eq',
        ExpressionAttributeNames: {
            '#organizationDomain': 'organizationDomain',
            '#active': 'active'
        },
        ExpressionAttributeValues: {
            ':organizationDomain': {
                S: organizationDomain
            },
            ':active_eq': {
                N: `${Constant.CORPORATION_ACTIVE.ACTIVE}`
            },
            ':block_eq':{
                N: `${Constant.CORPORATION_ACTIVE.BLOCK}`
            }
        }
    };

    return JSON.stringify(expression);
};

/**
 * prepareParamsGetAllPremiumCorporation
 * @returns 
 */
export const prepareParamsGetAllPremiumCorporation = () => {
    let params = {
        IndexName: Constant.GSI.SCAN,
        FilterExpression: '#contractType = :contractType_eq',
        ExpressionAttributeNames: {
            '#contractType': 'contractType'
        },
        ExpressionAttributeValues: {
            ':contractType_eq': {
                S: 'PREMIUM'
            }
        },
        Limit: Constant.DEFAULT_LIMIT
    };

    return JSON.stringify(params);
};

export const prepareParamsGetAllCorporation = () => {
    let params = {
        IndexName: Constant.GSI.SCAN,
        Limit: Constant.DEFAULT_LIMIT
    };

    return JSON.stringify(params);
}
