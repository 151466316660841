import Constant from '@/common/constant/Constant';
import { updateApiPublic, deleteUserLog } from '@/graphql/mutations';
import API from '@aws-amplify/api';
import { Storage } from 'aws-amplify';

export const pushClientLog = async (logs, loggerData) => {
    console.log({logs, loggerData})
    let paramsWithConfigOptions = {
        query: updateApiPublic,
        variables: {
            updateApiPublicRequestDto: JSON.stringify({
                updateApiType: Constant.UPDATE_API_PUBLIC_TYPE.PUSH_LOG_CLIENT,
                errorObject: logs,
                loggerData: loggerData
            })
        },
        options: {
            fetchPolicy: 'network-only'
        }
    };

    API.graphql(paramsWithConfigOptions)
        .then(res => {
            // console.log("BaseAPI res success", params)
        })
        .catch(err => {
            console.log(err);
        });
};

export const getLogFileUploadTicketByCsv = async keyFile => {
    try {
        let getFileResult = await Storage.get(keyFile, { download: true, cacheControl: 'no-cache' });
        return await new Response(getFileResult.Body).json();
        
    } catch (error) {
        console.log('getLogUploadTicketByCsv', error);
        return;
    }
}

export const getAllLogFileUploadTicketByCsv = async (listFileCache, prefixFolder) => {
    try {
        return await Promise.all(listFileCache.map(async (file) => {
            const fileKey =  prefixFolder + file.uploadKey.replace('public/', '');
            const contentFile = await getLogFileUploadTicketByCsv(fileKey);
            return contentFile;
        }));
    } catch (error) {
        console.log('getAllLogFileUploadTicketByCsv', error);
        return;
    }
};

/**
 * delete user log
 * @param {*} API 
 * @param {*} ticketId 
 * @returns 
 */
export const deleteUserLogs = async (requestDto) => {
    try {
        const result = await API.graphql({
            query: deleteUserLog,
            variables: {
                deleteUserLogRequestDto: requestDto,
            },
        });

        return result.data;
    } catch (err) {
        console.log('deleteUserLogs', err);
        return null;
    }
}
