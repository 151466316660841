import React from 'react';
import {pushClientLog} from '@/service/logService';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };

        window.onerror = (msg, url, line, col, error) => {
            const errorInfo = {
                name: 'Window onerror',
                msg: msg, 
                extra: {
                    column: col,
                    line: line,
                    url: url,
                    error: error,
                }
            };
           
            this.sendErrorInfoToServer(errorInfo);

            var suppressErrorAlert = true;
            // If you return true, then error alerts (like in older versions of 
            // Internet Explorer) will be suppressed..

            return suppressErrorAlert;
        };
        
        window.onunhandledrejection = (e) => {
            console.log('onunhandledrejection', e)
            // throw new Error(e.reason.stack);
        }

       
    }

   
    // static getDerivedStateFromError(error) {
    //     console.log('getDerivedStateFromError', error)
    
    //     // Update state so the next render will show the fallback UI.
    //     return { hasError: true };
    // }
    
    componentDidCatch(error, info) {
        console.log('componentDidCatch', {error, info});
        const errorInfo = {
            name: 'componentDidCatch error',
            extra: {error, info}
        };
        
        //  this.sendErrorInfoToServer(errorInfo);
    }

    sendErrorInfoToServer = (resError) => {
        pushClientLog(resError);
    }
  
    render() {
        if (this.state.hasError) {
            // render any custom fallback UI
        }
  
        return this.props.children; 
    }
}
export default ErrorBoundary;