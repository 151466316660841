// import React from 'react';
// import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';
// import { Authenticator } from 'aws-amplify-react';

// import { RecoilRoot } from 'recoil';
// import RecoilNexus from "recoil-nexus";

// const container = document.getElementById('root');
// const root = createRoot(container);

// root.render(
//   <RecoilRoot>
//     {/* <RecoilNexus /> */}
//     <BrowserRouter>
//       <Authenticator hideDefault={true}>
//         <App />
//       </Authenticator>
//     </BrowserRouter>
//   </RecoilRoot>
//   // document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Authenticator } from 'aws-amplify-react';

import { RecoilRoot } from 'recoil';
import RecoilNexus from "recoil-nexus";

ReactDOM.render(
  <RecoilRoot>
    <RecoilNexus />
    <BrowserRouter>
      <Authenticator hideDefault={true}>
        <App />
      </Authenticator>
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
