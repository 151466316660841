// prettier-ignore
const en = {
    'Sign In': 'Registrarse',
    'Sign Up': 'Regístrate',
    'global.field.empty': 'This field cannot be blank',
    'global.field.invalid': 'Please enter the correct format for this field',

    'create.organization.btn.submit': 'Create',
    'create.organization.msg.required.mailAddress': 'This field cannot be blank',
    //#region System_Msg
    'SYSMSG001': 'A server error occurred. \nPlease contact the administrator.',
    'SYSMSG002': 'Please check your network connection.',
    'SYSMSG003': 'A timeout occurred.',
    //#endregion

    "COMBTN001" : "Cancel",
    "COMBTN002" : "Yes",
    "COMBTN003" : "Apply",
    'COMBTN004' : 'OK',
    "COMBTN006" : "Close",
    'AUTH01MSG10': 'Subdomain does not exist.',

    'COMMSG014': 'This is a required field.',
    'COMMSG034': 'There is no Tag item.',

    'MSG-AI-TOOLTIP-WHEN-OPEN-AI-ON-KNOLEDGE-LIST-PANEL': 'Tooltip when open AI on knowledge list panel',

    // Chat bot
    'CBOTQUOTE01-1': 'AIの回答は正確性を保証するわけではありません。ご留意ください。　',
    'CBOTQUOTE01-2': 'Powered by GPT-4',

    'MSG-AI-ALLOW-ASK-IN-SPOKEN-LANGUAGE': '話し言葉で質問できます',
    "MSG-AI-RESPOND-TO-USER-MSG-ON-PREVIEW-KNOW-ROBO": "Your message has been replied by AI.",
};

export default en;
