import API from '@aws-amplify/api';
import Constant from '@/common/constant/Constant';
import { getApiPublic, publicGetKnowledgeList } from '@/graphql/queries';
import { updateApiPublic } from '@/graphql/mutations';

export const getPublicAPI = async (APIClient, params) => {
    try {
        let res = await API.graphql({
            query: getApiPublic,
            variables: {
                params: JSON.stringify(params)
            },
            authMode: Constant.AUTH_MODE_API_KEY,
        });
        const response = res.data.getApiPublic;
        return res.data.getApiPublic;
    } catch (err) {
        return {};
    }
};

export const updatePublicAPI = async (APIClient, params) => {
    try {
        let res = await API.graphql({
            query: updateApiPublic,
            variables: {
                updateApiPublicRequestDto: JSON.stringify({
                    ...params
                })
            },
            authMode: Constant.AUTH_MODE_API_KEY,
        });
        return res.data.updateApiPublic;
    } catch (err) {
        return {};
    }
};

export const publicGetKnowledgeListAPI = async (APIClient, params) => {
    try {
        let res = await API.graphql({
            query: publicGetKnowledgeList,
            variables: {
                params: JSON.stringify(params)
            },
            authMode: Constant.AUTH_MODE_API_KEY,
        });
        const response = res?.data?.publicGetKnowledgeList;
        return response;
    } catch (err) {
        return {};
    }
};