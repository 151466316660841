const UserLogConstant = {
    LOG_ACTION: {
        ADD: 'ADD',
        UPDATE: 'UPDATE',
        REMOVE: 'REMOVE',
        PUSH: 'PUSH',
    },

    LOG_TYPE: {
        CREATE_TICKET_KNOWLEDGE_BY_CSV: 'CREATE_TICKET_KNOWLEDGE_BY_CSV'
    },

    LOG_STATUS: {
        INPROGRESS: 1,
        OK: 2,
        NG: 3,
        ERROR: 4,
        MODE_DELETE_OK: 5,
        MODE_DELETE_ERROR: 6,
    },

    UPLOAD_STATUS: {
        INPROGRESS: 1,
        OK: 2,
        NG: 3,
        ERROR: 4,
    },
    DEFAULT_PAGE_SIZE: 100,

    ATTACHMENT_TYPE_LOG: {
        FORMAT: 'format',
        AUTHENTICATION: 'authentication',
        OTHER: 'other',
        EMPTY_DATA: 'empty_data',
        MAX_SIZE_UPLOAD: 'max_size_upload',
    }
}

export default UserLogConstant;
