import Utils from '@/common/utils/utils';
import Constant from '@/common/constant/Constant';
import { v4 as uuidv4} from 'uuid';

const AFFILIATION_ORGANIZATION = 'affiliation_organization';
const CORPORATION_DOMAIN_KEY = 'corporation_domain';
const LAST_CORP_DOMAIN = 'last_corp_domain';
const SELECTED_PROJECT_ID = 'selected_project_id';
const SELECTED_PROJECT_TYPE = 'selected_project_type';
const USER_DETAIL_ID_KEY = 'user_detail_id';
const USER_EMAIL = 'user_email'
const LIST_PROJECT_OF_USER = 'list_project';
const NOTIFICATION_REDIRECT_PANEL_INFO = 'notification_redirect_panel_info';
const GUEST_CUSTOMER_PROJECT = 'guest_customer_project';
const DESTINATION_PROJECT = 'destination';
const SUB_DOMAIN_IFRAME = 'sub_domain_iframe';
const CLIENT_UUID = 'client_uuid';
const KEY_SEARCH_URL = 'key_search_url';
const PUBLIC_KNOWLEDGE_DRILLDOWN_EXPANDED = 'public_knowledge_drilldown_expanded';
const SUPPORT_THREAD_ID = 'support_thread_id';
const SUPPORT_GEN_SCRIPT_THREAD_ID = 'support_gen_script_thread_id';
const CONTRACT_TYPE_AND_SUB_DOMAIN_CORP = 'contract_type__and_subdomain_corp';
const INFO_SHARE_LINK_TICKET = 'info_share_link_ticket';
const KNOWLEDGE_ID_VIEW_FROM_ROBO = 'knowledge_id_view_from_robo';
const SESSION_BROWSER_ID = 'session_browser_id';

const REMEMBER_ME = 'remember_';
const LIST_PUBLIC_RANGE_DEFAULT = 'listDataPublicRangeDefault';

/// Base utils
function setItem(key, value) {
    localStorage.setItem(key, value);
}

function getItem(key) {
    const value = localStorage.getItem(key);
    if (value) {
        return Utils.convertJSONDataToObject(value);
    }

    return value;
}

function setAffiliationOrganization(id) {
    setItem(AFFILIATION_ORGANIZATION, id);
}

function getAffiliationOrganization() {
    return getItem(AFFILIATION_ORGANIZATION);
}

function deleteAffiliationOrganization() {
    return deleteItem(AFFILIATION_ORGANIZATION);
}

function setUserEmail(email) {
    setItem(USER_EMAIL, email);
}

function getUserEmail() {
    return getItem(USER_EMAIL);
}

function deleteUserEmail() {
    return deleteItem(USER_EMAIL);
}

function setUserDetailId(id) {
    setItem(USER_DETAIL_ID_KEY, id);
}

function getUserDetailId() {
    return getItem(USER_DETAIL_ID_KEY);
}

function deleteUserDetailId() {
    deleteItem(USER_DETAIL_ID_KEY);
}

function deleteItem(key) {
    localStorage.removeItem(key);
}

function setCorporationDomain(corp_id) {
    setItem(CORPORATION_DOMAIN_KEY, corp_id);
}

function getCorporationDomain() {
    let corporationId = getItem(CORPORATION_DOMAIN_KEY);
    return Utils.isEmptyString(corporationId) ? '' : corporationId.toString();
}

function deleteCorporationDomain() {
    deleteItem(CORPORATION_DOMAIN_KEY);
}

function getGuestCustomerQueryParam() {
    return getItem(GUEST_CUSTOMER_PROJECT);
}

function setGuestCustomerProjectQueryParam(value) {
    setItem(GUEST_CUSTOMER_PROJECT, value)
}

function getDestinationProjectQueryParam() {
    return getItem(DESTINATION_PROJECT);
}

function setDestinationProjectQueryParam(value) {
    setItem(DESTINATION_PROJECT, value);
}

function getSubDomainIframe() {
    return getItem(SUB_DOMAIN_IFRAME);
}

function setSubDomainIframe(value) {
    setItem(SUB_DOMAIN_IFRAME, value);
}

function deleteSubDomainIframe() {
    deleteItem(SUB_DOMAIN_IFRAME);
}

function deleteQAanonymousParams() {
    deleteItem(GUEST_CUSTOMER_PROJECT);
    deleteItem(DESTINATION_PROJECT);
    deleteItem(SUB_DOMAIN_IFRAME);
}

//selected project content id
function setSelectedProjectId(selectedProjectId) {
    // console.trace("-------setSelectedProjectId---------")
    setItem(SELECTED_PROJECT_ID, selectedProjectId);
}

function getSelectedProjectId() {
    return getItem(SELECTED_PROJECT_ID);
}

function deleteSelectedProjectId() {
    deleteItem(SELECTED_PROJECT_ID);
}

function setSelectedProjectType(projectType) {
    setItem(SELECTED_PROJECT_TYPE, projectType);
}

function getSelectedProjectType() {
    return getItem(SELECTED_PROJECT_TYPE);
}

function deleteSelectedProjectType() {
    deleteItem(SELECTED_PROJECT_TYPE);
}

function setListProjectOfUser(projectList) {
    setItem(LIST_PROJECT_OF_USER, projectList);
}
function getListProjectOfUser() {
    return getItem(LIST_PROJECT_OF_USER);
}

function deleteLisProjectOfUser() {
    deleteItem(LIST_PROJECT_OF_USER);
}

function setNotificationRedirectPanelInfo(info) {
    setItem(NOTIFICATION_REDIRECT_PANEL_INFO, info);
}

function getNotificationRedirectPanelInfo() {
    return getItem(NOTIFICATION_REDIRECT_PANEL_INFO);
}

function deleteNotificationRedirectPanelInfo() {
    return deleteItem(NOTIFICATION_REDIRECT_PANEL_INFO);
}

function getClientUUid() {
    return getItem(CLIENT_UUID);
}

function setClientUUId(value) {
    setItem(CLIENT_UUID, value)
}

function deleteClientUUId() {
    deleteItem(CLIENT_UUID)
}

function getInfoShareLinkTicket() {
    return getItem(INFO_SHARE_LINK_TICKET);
}

function setInfoShareLinkTicket(value) {
    return setItem(INFO_SHARE_LINK_TICKET, value);
}

function deleteInfoShareLinkTicket() {
    deleteItem(INFO_SHARE_LINK_TICKET)
}

function setRememberMe(value, ttl = Constant.TIME_TO_LIVE_30_DAYS) {
    const now = new Date();
    value.password = Utils.encodeDataByCrypto(
        value.password,
        Constant.SECRET_KEY_ENCRYPT_GENERATE_DATA
    );
    const item = {
        value: value,
        expiry: now.getTime() + ttl
    };
    setItem(REMEMBER_ME + value.userOrgCode, JSON.stringify(item));
}

function getRememberMe(orgCode) {
    const itemStr = getItem(REMEMBER_ME + orgCode);
    const now = new Date();
    // If the item doesn't exist, return null
    if (itemStr !== null) {
        // Compare the expiry time of the item with the current time
        if (now.getTime() > itemStr.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            deleteItem(REMEMBER_ME);
            return null;
        }
        itemStr.value.password = Utils.decodeDataByCrypto(
            itemStr.value.password,
            Constant.SECRET_KEY_ENCRYPT_GENERATE_DATA
        );
        return itemStr.value;
    }
    return itemStr;
}

function deleteRememberMe(orgCode) {
    return deleteItem(REMEMBER_ME + orgCode);
}

function setLastLoginCorpDomain(corpDomain) {
    setItem(LAST_CORP_DOMAIN, corpDomain);
}

function getLastLoginCorpDomain() {
    let corporationId = getItem(LAST_CORP_DOMAIN);
    return Utils.isEmptyString(corporationId) ? '' : corporationId.toString();
}

function deleteLastLoginCorpDomain() {
    deleteItem(LAST_CORP_DOMAIN);
}

function setKeySearchUrl(value) {
    setItem(KEY_SEARCH_URL, value);
}
function getKeySearchUrl() {
    return getItem(KEY_SEARCH_URL);
}

function setPublicKnowledgeDrilldownExpanded(value) {
    setItem(PUBLIC_KNOWLEDGE_DRILLDOWN_EXPANDED, value);
}
function getPublicKnowledgeDrilldownExpanded() {
    return getItem(PUBLIC_KNOWLEDGE_DRILLDOWN_EXPANDED);
}

function setContractTypeAndSubDomainCorp(value) {
    setItem(CONTRACT_TYPE_AND_SUB_DOMAIN_CORP, value);
}
function getContractTypeAndSubDomainCorp() {
    return getItem(CONTRACT_TYPE_AND_SUB_DOMAIN_CORP);
}

function setAnonymousSupportId(obj, chatBotOrigin = ''){
    let storageKey = SUPPORT_THREAD_ID;
    if (chatBotOrigin && chatBotOrigin === Constant.CHAT_BOT_ORIGIN.GEN_SCRIPT) {
        storageKey = SUPPORT_GEN_SCRIPT_THREAD_ID;
    }
    if(Utils.isEmptyObject(obj)){
        obj = {};
    }
    
    setItem(storageKey, JSON.stringify(obj));
}

function getAnonymousSupportId(chatBotOrigin = ''){
    let storageKey = SUPPORT_THREAD_ID;
    if (chatBotOrigin && chatBotOrigin === Constant.CHAT_BOT_ORIGIN.GEN_SCRIPT) {
        storageKey = SUPPORT_GEN_SCRIPT_THREAD_ID;
    }
   const strObj = getItem(storageKey);
   return Utils.convertJSONDataToObject(strObj);
}

function setKnowledgeIdViewFromRobo(value) {
    setItem(KNOWLEDGE_ID_VIEW_FROM_ROBO, value);
}
function getKnowledgeIdViewFromRobo() {
    return getItem(KNOWLEDGE_ID_VIEW_FROM_ROBO);
}

function setSessionBrowserId(value) {
    if (!value) {
        value = `solutiondesk_${uuidv4()}`
    }
    setItem(SESSION_BROWSER_ID, value);
}
function getSessionBrowserId() {
    return getItem(SESSION_BROWSER_ID);
}
function deleteSessionBrowserId() {
    return deleteItem(SESSION_BROWSER_ID);
}

function setListDataPublicRangeDefault(dataRole) {
    setItem(LIST_PUBLIC_RANGE_DEFAULT, JSON.stringify(dataRole));
}

function getListDataPublicRangeDefault() {
    return getItem(LIST_PUBLIC_RANGE_DEFAULT);
}

function deleteListDataPublicRangeDefault() {
    deleteItem(LIST_PUBLIC_RANGE_DEFAULT);
}

const localStorageUtil = {
    setAffiliationOrganization,
    getAffiliationOrganization,
    deleteAffiliationOrganization,
    setCorporationDomain,
    getCorporationDomain,
    deleteCorporationDomain,
    setSelectedProjectId,
    getSelectedProjectId,
    deleteSelectedProjectId,
    setSelectedProjectType,
    getSelectedProjectType,
    deleteSelectedProjectType,
    setUserDetailId,
    getUserDetailId,
    deleteUserDetailId,
    setListProjectOfUser,
    getListProjectOfUser,
    deleteLisProjectOfUser,
    setNotificationRedirectPanelInfo,
    getNotificationRedirectPanelInfo,
    deleteNotificationRedirectPanelInfo,
    setRememberMe,
    getRememberMe,
    deleteRememberMe,
    getItem,
    setItem,
    getGuestCustomerQueryParam,
    setGuestCustomerProjectQueryParam,
    getDestinationProjectQueryParam,
    setDestinationProjectQueryParam,
    deleteQAanonymousParams,
    getSubDomainIframe,
    setSubDomainIframe,
    deleteSubDomainIframe,
    getClientUUid,
    setClientUUId,
    deleteClientUUId,
    setLastLoginCorpDomain,
    getLastLoginCorpDomain,
    deleteLastLoginCorpDomain,
    setKeySearchUrl,
    getKeySearchUrl,
    setPublicKnowledgeDrilldownExpanded,
    getPublicKnowledgeDrilldownExpanded,
    setAnonymousSupportId,
    getAnonymousSupportId,
    setUserEmail,
    getUserEmail,
    deleteUserEmail,
    setContractTypeAndSubDomainCorp,
    getContractTypeAndSubDomainCorp,
    getInfoShareLinkTicket,
    setInfoShareLinkTicket,
    deleteInfoShareLinkTicket,
    setKnowledgeIdViewFromRobo,
    getKnowledgeIdViewFromRobo,
    setSessionBrowserId,
    getSessionBrowserId,
    deleteSessionBrowserId,
    setListDataPublicRangeDefault,
    getListDataPublicRangeDefault,
    deleteListDataPublicRangeDefault
};

export default localStorageUtil;
